var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "journal-form pa-2", attrs: { elevation: 0, light: false } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.form.id }
          }),
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.form.uen }
          }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "" } },
                [
                  _c("v-textarea", {
                    staticClass: "journal-title",
                    attrs: {
                      outline: "",
                      rules: _vm.rules.title,
                      placeholder: _vm.$t(
                        "app.tools.journal.fields.title.placeholder"
                      ),
                      rows: "1",
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    staticClass: "journal-description",
                    attrs: {
                      outline: "",
                      rules: _vm.rules.description,
                      placeholder: _vm.$t(
                        "app.tools.journal.fields.description.placeholder"
                      ),
                      rows: "5",
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grow: "",
                    justify: "start",
                    color: "primary"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "journal-date no-underline journal-form-date",
                    class: { "form-modal-open": _vm.dateModalOpen },
                    attrs: {
                      readonly: "",
                      rules: _vm.rules.required,
                      "hide-details": ""
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.dateModalOpen = true
                      },
                      "click:prepend": function($event) {
                        $event.stopPropagation()
                        _vm.dateModalOpen = true
                      }
                    },
                    model: {
                      value: _vm.formattedDate,
                      callback: function($$v) {
                        _vm.formattedDate = $$v
                      },
                      expression: "formattedDate"
                    }
                  })
                ],
                1
              ),
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", lg7: "", xl8: "" } },
                  [
                    _c(
                      "v-dialog",
                      {
                        ref: "dateDialog",
                        attrs: {
                          "return-value": _vm.dateField,
                          persistent: "",
                          lazy: "",
                          "full-width": "",
                          width: "290px",
                          "content-class": "radius-15"
                        },
                        on: {
                          "update:returnValue": function($event) {
                            _vm.dateField = $event
                          },
                          "update:return-value": function($event) {
                            _vm.dateField = $event
                          }
                        },
                        model: {
                          value: _vm.dateModalOpen,
                          callback: function($$v) {
                            _vm.dateModalOpen = $$v
                          },
                          expression: "dateModalOpen"
                        }
                      },
                      [
                        _c(
                          "v-date-picker",
                          {
                            attrs: {
                              color: "primary",
                              locale: _vm.currentLanguage,
                              scrollable: ""
                            },
                            model: {
                              value: _vm.dateField,
                              callback: function($$v) {
                                _vm.dateField = $$v
                              },
                              expression: "dateField"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { flat: "" },
                                on: { click: _vm.toggleDateModal }
                              },
                              [_vm._v(_vm._s(_vm.$t("common.actions.cancel")))]
                            ),
                            _c(
                              "primary-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.dateDialog.save(
                                      _vm.dateField
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("common.actions.save")) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "mt-2": "",
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _vm.cancellable
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c(
                                "action-buttons",
                                {
                                  attrs: {
                                    primary: {
                                      label: _vm.$t("common.actions.save"),
                                      disabled: !_vm.valid,
                                      loading: _vm.loading
                                    },
                                    secondary: {
                                      label: _vm.$t("common.actions.cancel")
                                    }
                                  },
                                  on: {
                                    "primary-clicked": _vm.submitAction,
                                    "secondary-clicked": _vm.onCancel
                                  }
                                },
                                [_vm._v("\n            >\n            ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-center": "" } },
                            [
                              _c("action-buttons", {
                                attrs: {
                                  primary: {
                                    label: _vm.$t("common.actions.save"),
                                    disabled: !_vm.valid,
                                    loading: _vm.loading
                                  }
                                },
                                on: { "primary-clicked": _vm.submitAction }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }