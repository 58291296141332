<template>
  <v-layout row wrap gap-xs-2 gap-sm-3 class="action-buttons">
    <v-flex shrink>
      <secondary-button
              v-if="secondary" outline depressed
              :disabled="secondary.disabled || false"
              :loading="secondary.loading || false"
              @click.stop="secondaryClick">
        <label class="mx-2">{{ secondary.label }}</label>
      </secondary-button>
    </v-flex>

    <v-flex shrink>
      <primary-button
              v-if="primary" depressed
              :disabled="primary.disabled || false"
              @click.stop="actionClick"
              :loading="primary.loading || false">
        <label class="mx-2">{{ primary.label }}</label>
      </primary-button>
    </v-flex>
  </v-layout>
</template>

<script>
import primaryButton from '@/views/components/button/primary-button.vue';
import secondaryButton from '@/views/components/button/secondary-button.vue';

export default {
  name: 'actionButtons',
  components: { primaryButton, secondaryButton },
  props: {
    primary: {
      type: Object,
      required: true,
    },
    secondary: {
      type: Object,
      required: false,
    },
  },
  methods: {
    actionClick() {
      this.$emit('primary-clicked');
    },
    secondaryClick() {
      this.$emit('secondary-clicked');
    },
  },
};
</script>
