var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "action-buttons",
      attrs: { row: "", wrap: "", "gap-xs-2": "", "gap-sm-3": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _vm.secondary
            ? _c(
                "secondary-button",
                {
                  attrs: {
                    outline: "",
                    depressed: "",
                    disabled: _vm.secondary.disabled || false,
                    loading: _vm.secondary.loading || false
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.secondaryClick($event)
                    }
                  }
                },
                [
                  _c("label", { staticClass: "mx-2" }, [
                    _vm._v(_vm._s(_vm.secondary.label))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _vm.primary
            ? _c(
                "primary-button",
                {
                  attrs: {
                    depressed: "",
                    disabled: _vm.primary.disabled || false,
                    loading: _vm.primary.loading || false
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.actionClick($event)
                    }
                  }
                },
                [
                  _c("label", { staticClass: "mx-2" }, [
                    _vm._v(_vm._s(_vm.primary.label))
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }