













































































































import Vue from 'vue';
import { format, parse } from 'date-fns';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';
import store from '@/scripts/store';
import actionButtons from '@/views/components/navigation/action-buttons.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

const DATE_FORMAT = 'yyyy-MM-dd';

export default Vue.extend({
  name: 'journal-form',
  components: { actionButtons, PrimaryButton },
  props: {
    journal: {
      type: Object as () => IJournal,
      required: true,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      dateModalOpen: false,
      dateField: undefined as string | undefined,
      form: {} as IJournal,
      rules: {
        required: [ (v: string) => !!v || this.$t('common.errors.required') ],
        title: [
          (v: string) => !!v || this.$t('common.errors.required'),
          (v: string) =>
            !v || v.length <= 255 || this.$t('common.errors.max255'),
        ],
      },
    };
  },
  computed: {
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    formattedDate: {
      get(): string | null {
        return this.dateField
          ? format(
            parse(this.dateField, DATE_FORMAT, new Date()),
            this.abbreviatedDateFormat,
            { locale: this.currentLocale },
          )
          : null;
      },
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    user(): any {
      return this.$store.getters.user;
    },
  },
  watch: {
    journal: {
      immediate: true,
      handler(newValue) {
        this.setJournal(newValue);
      },
    },
  },
  mounted() {
    this.setJournal(this.journal);
    this.dateField = format(
      new Date(this.journal.journalDate || new Date()),
      DATE_FORMAT,
    );
  },
  methods: {
    setJournal(journal: IJournal) {
      this.form = {
        id: journal.id,
        title: journal.title,
        description: journal.description,
        journalDate: journal.journalDate,
        uen: journal.uen,
        deleted: journal.deleted,
        tags: journal.tags,
      } as IJournal;

      let dateField;

      if (journal.journalDate) {
        const date = new Date(journal.journalDate);
        dateField = format(date, DATE_FORMAT);
      }

      this.dateField = dateField;

      if (this.$refs && this.$refs.form) {
        const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
        formElement.resetValidation();
      }
    },
    submitAction() {
      const formElement: HTMLFormElement = this.$refs.form as HTMLFormElement;
      if (formElement.validate()) {
        const journal = Object.assign({}, this.form) as IJournal;
        this.$log.debug('Submitting journal', journal);

        const date = this.dateField?.replace(/-/g, '/');
        journal.journalDate = new Date(`${date}`);

        if (journal.id) {
          this.updateJournal(journal);
        } else {
          this.createJournal(journal);
        }
      }
    },
    createJournal(journal: IJournal) {
      this.loading = true;
      this.$store
        .dispatch('journals/createJournal', journal)
        .then(res => {
          this.$emit('update', res);
        })
        .catch(() => {
          this.$store.commit('setNotification', {
            text: this.$t('app.tools.journal.notifications.errored'),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateJournal(journal: IJournal) {
      this.loading = true;
      this.$store
        .dispatch('journals/updateJournal', journal)
        .then(res => {
          this.$emit('update', res);
        })
        .catch(() => {
          this.$store.commit('setNotification', {
            text: this.$t('app.tools.journal.notifications.errored'),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleDateModal() {
      this.dateModalOpen = !this.dateModalOpen;
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
});
